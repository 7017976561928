<template>
  <div>
    <el-dialog
      top="12%"
      :visible.sync="dialogVisible"
      width="458px"
      class="pop-dialog sure-dialog"
      :modal-append-to-body="false"
    >
      <div class="sure-dialog-content">
        <span class="fz-18">是否确认注销该用户？</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmAccountCancel" class="confirm-button" :loading="btnLoading">确 定</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import customerApi from "@/api/customer";

export default {
  data() {
    return {
      dialogVisible: false,
      customerID: undefined,
      btnLoading:false
    };
  },
  methods: {
    confirmAccountCancel() {
      this.btnLoading=true;
      customerApi.customerDel(this.customerID).then((res) => {
        if (res.data.code === 10200) {
          this.hide();
          this.updateList();
          this.$$success("注销成功");
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false;
      });
    },

    show(id) {
      this.dialogVisible = true;
      this.customerID = id;
    },

    hide() {
      this.dialogVisible = false;
    },

    updateList() {
      this.$emit("update");
    },
  },
};
</script>
