<template>
  <div>
    <!-- 新增弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      title="新增用户"
      :visible.sync="dialogVisible"
      width="444px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <el-form ref="form" :model="form" label-width="80px" label-position="left" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名称" prop="username">
              <el-input
                v-model="form.username"
                class="dialog-input"
                placeholder="输入用户名称"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="form.email"
                class="dialog-input"
                placeholder="输入用户邮箱"
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户密码" prop="password">
              <el-input
                v-model="form.password"
                class="dialog-input"
                placeholder="输入用户密码"
                show-password
                autocomplete="false"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="form.phone" class="dialog-input" placeholder="输入手机号码" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="用户性别" prop="gender">
              <el-select v-model="form.gender" placeholder="请选择">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公司" prop="company">
              <el-input v-model="form.company" class="dialog-input" placeholder="输入公司名称" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="customerAdd" class="confirm-button" :loading="btnLoading">新增</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import customerApi from "@/api/customer";
import customer from "@/mixins/customer";
import formValidations from "@/mixins/form-validations";

export default {
  mixins: [customer],
  data() {
    return {
      dialogVisible: false,
      form: {
        username: "",
        company: "",
        password: "",
        phone: "",
        gender: "",
      },
      btnLoading:false
    };
  },
  methods: {
    customerAdd() {
      this.validForm(this.customerAddConfirm);
    },
    // 确认新增用户
    customerAddConfirm() {
      this.encryptPassword();
      this.btnLoading=true;
      let data = {
        username: this.form.username,
        email: this.form.email,
        company: this.form.company,
        password: this.form.passwordEncrypt,
        gender: this.form.gender,
        phone: this.form.phone,
      };
      customerApi.customerAdd(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success("新增用户成功!");
          this.dialogVisible = false;
          this.updateList();
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false;
      });
    },

    show() {
      this.dialogVisible = true;
      this.clear();
    },

    hide() {
      this.dialogVisible = false;
      this.clear();
    },

    updateList() {
      this.$emit("update");
    },

    clear() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
  },
};
</script>
