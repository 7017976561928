<template>
  <div>
    <!-- 状态变更 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      title="状态变更"
      :visible.sync="dialogVisible"
      width="530px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="用户名称" prop="username">
          <span>{{ form.username }}</span>
        </el-form-item>

        <el-form-item label="用户邮箱" prop="email">
          <span>{{ form.email }}</span>
        </el-form-item>

        <el-form-item label="用户状态" prop="status">
          <el-radio
            v-for="item in statusInfo"
            :key="item.value"
            :label="item.value"
            v-model="form.status"
            :disabled="item.name == '锁定' ? true : false"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
         <el-form-item label="订阅状态" prop="type">
          <span>{{ getSubscriptionType(type) }}</span>
        </el-form-item>
        <el-form-item label="更改状态" prop="subscriptionType">
          <el-radio
            v-for="item in subscription"
            :key="item.value"
            :label="item.value"
            v-model="subscriptionType"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
        <el-form-item label="市场" prop="market">
          <el-radio
            v-for="item in marketArr"
            :key="item.value"
            :label="item.value"
            v-model="market"
            >{{ item.name }}</el-radio
          >
        </el-form-item>
        <el-form-item label="次数/个数" prop="count">
          <el-input
            v-model="count"
            class="integral-input"
            placeholder="请填数量"
          />
        </el-form-item>
        <el-form-item label="到期时间" prop="expiration_time">
          <el-date-picker
            style="width:240px"
            v-model="expiration_time"
            type="date"
            popper-class="expired"
            :picker-options="pickerOptions"
            :disabled="!subscriptionType"
            value-format="timestamp"
            :clearable="false"
            prefix-icon="none"
            placeholder="00-00-00"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmChange" class="confirm-button"
        :loading="btnLoading"
          >确 认</el-button
        >
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import customerApi from "@/api/customer";
import vars from "../vars";
import moment from "moment";

export default {
  computed: {
    pickerOptions() {
      const pickerOptions ={
      disabledDate(time) {
          return moment(time).isBefore(moment()) || moment(time).isAfter(moment().add(10, 'years'))
        },
      };
      return pickerOptions;
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {},
      statusInfo: vars.statusInfo,
      btnLoading:false,
      currentInfo:null,
      type:'', // 用户当前订阅类型
      subscriptionType:'',// 修改的订阅类型
      subscription: [],
      expiration_time: '',
      count: 1000,
      market:'',
      optionsType:[
        { name: '基础版',value: 110},
        { name: '专业版',value: 120},
        { name: '大师版',value: 130},
        { name: '企业版',value: 140}
      ],
      marketArr:[
        {name: 'CN',value: 'CN'},
        {name: 'US',value: 'US'},
      ]
    };
  },
    watch: {
    "subscriptionType": function (val, oldVal) {
      if (this.form.subscription_type != this.subscriptionType) {
        this.expiration_time =  new Date(moment().add(1, 'day')).getTime();// 默认当前时间 + 1 uTC 时间戳;
      }
    },
  },
  methods: {
    clear() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      this.expiration_time ='';
      this.market= '';
    },
    async confirmChange() {
      this.btnLoading=true;
      if(`${(this.currentInfo && this.currentInfo.status) || ''}` !== `${this.form.status}`) {
        await this.changeStatus();
      }
       // 如果当前type为未订阅时，且有到期时间。
       //如果有订阅，且到期时间有变化，则认为修改订阅
      if ((this.subscriptionType !==`${this.form.subscription_type}`)
      || ((this.expiration_time && `${this.currentInfo.subscription_expired}` !== `${Math.round(new Date(this.expiration_time).getTime()/1000)}`))
      ) {
       await this.changeSubscription();
      }
     this.btnLoading=false;
    },
    /**
    * 修改订阅状态
    */
    changeSubscription() {
        if (!this.subscriptionType) {
           this.$$error('请选择更改状态');
           return;
        }
        let data = {
        customer_id: this.form.id,  //用户uid
        subscription_type: this.subscriptionType,
        expiration_time: Math.round(new Date(this.expiration_time).getTime()/1000),
        count: this.count,
        market: this.market
        }
        try {
          customerApi.changeSubscription(data).then((res) => {
            if (res.data.code === 10200) {
              this.updateList();
            } else {
             this.$$error(res.data.message);
             }
          });
         } catch (e) {
            return e;
        }
    },
    /**
    * 修改用户状态
    */
    changeStatus() {
      const statusData = {
        id: this.form.id,
        status: this.form.status,
      };
      try{
        customerApi.changeStatus(statusData).then((res) => {
          if (res.data.code === 10200) {
            this.updateList();
          } else {
            this.$$error(res.data.message);
          }
        });
      } catch (e) {
        return e;
       }
    },
   /**
   * 显示弹窗
    * @param {*} row 值
   */
    async show(row) {
      this.form = { ...row };
      await this.getCustomerInfo();
      this.dialogVisible = true;
    },
   /**
   * 关闭弹窗
   */
    hide() {
      this.dialogVisible = false;
      this.clear();
    },
    //
    getCustomerInfo() {
      try{
        customerApi.customerInfo({id: this.form.id}).then((res) => {
            if (res.data.code === 10200) {
                this.currentInfo = res.data.data;
                // 订阅类型 0什么都没有 已过期 110 基础版本 ，120 专业版 130 大师版  140 企业版
                this.subscriptionType = this.form.subscription_type;
                this.setSubscription(this.currentInfo);
            }
        });
       } catch (e) {
          return e;
        }
    },
   /**
   * 更新列表数据
   */
    updateList() {
      this.hide();
      this.$$success("更改成功");
      this.$emit("update");
    },
   /**
   * 显示订阅状态
    * @param {*} status 值
   */
    getSubscriptionType(status) {
      switch (status) {
        case 0:
        case '0':
            return "未订阅";
        case 110:
        case '110':
            return "基础版";
        case 120:
        case '120':
            return "专业版";
        case 130:
        case '130':
            return "大师版";
        case 140 :
        case '140':
            return "企业版"
        default :
        return "未订阅"
      }
    },
   /**
   * 设置可修改的 订阅状态
    * @param {*} type 值
   */
    setSubscription(current) {
      this.subscription = [];
      this.expiration_time = current ? current.subscription_expired * 1000 : '';
      this.subscription = this.optionsType;
        //   if (`${this.type}`=== '1'){
        //     this.subscription.push(this.optionsType);
        //     this.subscriptionType = 1;
        //   } else if (`${this.type}`=== '2'){
        //     this.subscription.push(this.optionsType[1]);
        //     this.subscriptionType = 2;
        //   } else if (`${this.type}`=== '0' || !this.type) {
        //     this.subscriptionType = '';
        //     this.subscription = this.optionsType;
        //     this.expiration_time = '';
        //   }
    }
  }
};
</script>
