export default {
  tabPane: [
    {
      name: '下载记录',
      data: [],
      tableColumns: [{
          prop: "asset_uid",
          label: "外部ID",
          width: 280
        },
        {
          prop: "name",
          label: "资产名称",
          width: 280
        },
        {
          prop: "download_count",
          label: "重复下载次数",
          width: 280
        },
        {
          prop: "download_size",
          label: "文件大小统计"
        }
      ],
      pagesize: 10,
      total: 0,
      pageNumber: 1,
      permission: "customer_downloads"
    },
    {
        name: '订阅记录', // 订阅信息历史
        data: [],
        tableColumns: [{
            prop: "type",
            label: "订阅版本",
            width: 180
          },
          {
            prop: "channel",
            label: "订阅方式",
            width: 210
          },
          {
            prop: "amount",
            label: "数量",
            width: 120
          },
          {
            prop: "balance",
            label: "包含积分",
            width: 120
          },
         {
            prop: 'created_at',
            label: '订阅日期',
            width: 200
         },
         {
            prop: 'expired_at',
            label: '到期日期',
            width: 200
         },
         {
          prop:"comment",
          label: '订单号'
        }
        ],
        pagesize: 10,
        pageNumber: 1,
        total: 0,
        permission: "customer_purchased"
      },
    {
      name: '交易记录(US)',
      data: [],
      tableColumns: [{
          prop: "created_at",
          label: "交易时间",
          width: 185
        },
        {
          prop: "order_number",
          label: "订单号",
          width: 185
        },
        {
          prop: "relation_order",
          label: "关联订单",
          width: 185
        },
        {
          prop: "purchase_plan_id",
          label: "积分方案ID",
          width: 185
        },
        {
          prop: "purchase_plan_name",
          label: "积分方案名称",
          width: 185
        },
        {
          prop: "grandtotal",
          label: "支付金额",
          width: 185
        },
        {
          prop: "payment_type",
          label: "支付方式"
        }
      ],
      pagesize: 10,
      total: 0,
      pageNumber: 1,
      permission: "order_management"

    },
    {
        name: '交易记录(CN)',
        data: [],
        tableColumns: [{
            prop: "created_at",
            label: "交易时间",
            width: 185
          },
          {
            prop: "order_number",
            label: "订单号",
            width: 330
          },
          {
            prop: "relation_order",
            label: "关联订单",
            width: 185
          },
          {
            prop: "purchase_plan_id",
            label: "积分方案ID",
            width: 135
          },
          {
            prop: "purchase_plan_name",
            label: "积分方案名称",
            width: 185
          },
          {
            prop: "grandtotal",
            label: "支付金额",
            width: 185
          },
          {
            prop: "payment_method",
            label: "支付方式"
          }
        ],
        pagesize: 10,
        total: 0,
        pageNumber: 1,
        permission: "order_management"

      },
    {
      name: '收藏资产', // 收藏资产
      data: [],
      tableColumns: [{
          prop: "preview",
          label: "缩略图",
          width: 160
        },
        {
          prop: "created_at",
          label: "时间",
          width: 260
        },
        {
          prop: "asset_uid",
          label: "外部ID",
          width: 260
        },
        {
          prop: "name",
          label: "资产名称"
        },

      ],
      pagesize: 10,
      pageNumber: 1,
      total: 0,
      permission: "customer_purchased"
    }
  ],
  statusInfo: [{
      value: 2,
      name: "邮箱未认证"
    },
    {
      value: 1,
      name: "正常"
    },
    {
      value: 0,
      name: "停用"
    },
    {
      value: 3,
      name: '锁定'
    }
  ],
  statusDisplay: {
    2: "邮箱未认证",
    1: "正常",
    0: "停用",
    3: "锁定"
  },
  profileInfo: [{
      value: "register_market",
      label: "市场"
    },
    {
      value: "profession",
      label: "职业"
    },
    {
      value: "company",
      label: "公司"
    },
    {
      value: "location",
      label: "国家"
    },
    {
      value: "industry",
      label: "行业"
    },
    {
      value: "website",
      label: "网站"
    },
  ],
  subscriptionInfo: [
    {
      value: "subscription_info",
      label: "订阅名称"
    },
    {
      value: "subscription_expired",
      label: "到期时间"
    },
    {
      value: "subscribe_desc",
      label: "购买订阅",
      text: '/赠送订阅(天)'
    },
  ],
  actionInfo: [
    {
      label: "下载次数",
      value: "download_count"
    },
    {
      value: "favorite_count",
      label: "关注资产"
    },
    {
      value: "download_size",
      label: "累计下载"
    },
    {
      value: "order_amount",
      label: "累计充值"
    },
    {
      label: "累计下载次数",
      value: "download_total"
    },
  ],
}
