<template>
  <div>
    <el-dialog top="12%"
      title="积分变更"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      class="pop-dialog aops-points-dialog"
      width="444px"
      :modal-append-to-body="false"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        :rules="addPointsRules"
      >
        <el-form-item label="用户名称" prop="username">
          <span>{{ form.username }}</span>
        </el-form-item>

        <el-form-item label="用户邮箱" prop="email">
          <span>{{ form.email }}</span>
        </el-form-item>
        <el-form-item label="变更积分" prop="amount">
         <div class="aop-type-div">
          <div :class="type === 1 ? 'aop-update-bg aop-btm-border':'aop-update-type aop-btm-border'" @click="type = 1">+</div>
          <div :class="type === 2? 'aop-update-bg': 'aop-update-type'" @click="type = 2">—</div>
         </div>
          <el-input
            v-model="form.amount"
            class="integral-input"
            placeholder="请填写积分数量"
          />
        </el-form-item>
          <el-form-item label="现有积分" prop="balance">
          <span>{{ form.balance }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmAdd" class="confirm-button" :loading="btnLoading">确 认</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customerApi from "@/api/customer";
import customer from "@/mixins/customer";
import formValidations from "@/mixins/form-validations"

export default {
  mixins: [formValidations],
  data() {
    return {
      dialogVisible: false,
      form: {},
      btnLoading:false,
      type: 1 // 1：增加积分，2：减少积分
    };
  },
  methods: {
    /**
    * 确认提交
    */
    confirmAdd() {
      if (parseInt(this.form.balance) + parseInt(this.form.amount) > 99999999) {
        this.$$error("积分总量不能超过99999999");
        return;
      }
      if (this.type === 2 && this.form.amount > this.form.balance) {
        this.$$error("已超出此用户账户内积分余额，请重新输入");
        return;
      }
      if (parseInt(this.form.amount) > 100) {
        this.$$error("所填积分数不可大于100");
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading=true;
          let data = {
            customer_id: this.form.id,
            amount: this.type === 2 ? `-${this.form.amount}` : this.form.amount,
          };
          customerApi.updataBalance(data).then((resp) => {
            if (resp.data.code === 10200) {
              this.$$success("操作成功!");
              this.updateList();
            } else {
              this.$$error(resp.data.message);
            }
            this.dialogVisible = false;
            this.btnLoading=false;
          });
        }
      });
    },
   /**
    * 显示弹窗
    */
    show(row) {
      this.type = 1;
      this.dialogVisible = true;
      this.form = { ...row, amount: undefined };
    },
   /**
    * 关闭弹窗
    */
    hide() {
      this.dialogVisible = false;
      this.clear();
    },
   /**
    * 数据更新发送消息
    */
    updateList() {
      this.$emit("update");
    },
   /**
    * 清空重置
    */
    clear() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
  },
};
</script>

<style lang="less">
@import "../scss/add-points.less";
</style>

